import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://admin.iuids.com:3002',
  // baseURL: 'https://localhost:3002',
  withCredentials: true, // 允许跨域传递 cookie （登录）
  timeout: 10000
})

export const get = async (url, params = {}) => {
  try {
    // 获取 Token
    const token = localStorage.getItem('token')

    // if (!token) {
    //   throw new Error('Token missing')
    // }

    // 发送请求
    const response = await instance.get(url, {
      params, // 请求的参数
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data // 返回请求的数据
  } catch (err) {
    console.error('请求失败:', err)
    throw err // 抛出错误，前端可以捕获并处理
  }
}

export const post = async (url, data = {}) => {
  try {
    // 获取 Token，可以优化成获取到一个全局状态，而非每次都从localStorage读取
    const token = localStorage.getItem('token')

    // 如果token不存在，可以跳转到登录页面，或者直接返回错误
    // if (!token) {
    //   throw new Error('Token missing')
    // }

    // 发送请求
    const response = await instance.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    return response.data // 返回请求的数据
  } catch (err) {
    // 错误处理
    console.error('请求失败:', err)
    throw err // 抛出错误，前端可以捕获并处理
  }
}

export const patch = async (url, data = {}) => {
  try {
    // 获取 Token
    const token = localStorage.getItem('token')

    // if (!token) {
    //   throw new Error('Token missing')
    // }

    // 发送请求
    const response = await instance.patch(url, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })

    return response.data // 返回请求的数据
  } catch (err) {
    // 错误处理
    console.error('请求失败:', err)
    throw err // 抛出错误，前端可以捕获并处理
  }
}

export const del = async (url, params = {}) => {
  try {
    // 获取 Token
    const token = localStorage.getItem('token')

    // if (!token) {
    //   throw new Error('Token missing')
    // }

    // 发送请求
    const response = await instance.delete(url, {
      params, // 请求的参数
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return response.data // 返回请求的数据
  } catch (err) {
    console.error('请求失败:', err)
    throw err // 抛出错误，前端可以捕获并处理
  }
}
